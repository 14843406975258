import { Button } from '@dop-ui/react/shared/ui/button';
import { RefreshIcon } from '@daouoffice/ui/lib/icons/dop/24/Refresh';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { KeyboardEvent, useEffect, useState } from 'react';

export interface Props {
  className?: string;
  imageSource: string;
  handleOnKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
  onRefresh: () => void;
  onCaptchaTextChange: (captchaText: string) => void;
}

export function Captcha({
  className,
  imageSource,
  handleOnKeyPress,
  onRefresh,
  onCaptchaTextChange,
}: Props) {
  const { t } = useTranslation('component');
  const localizedText = {
    captchaDescription: t('login.form.captcha.description'),
    captchaPlaceholder: t('login.form.captcha.placeholder'),
  };
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    setImgSrc(imageSource);
  }, [imageSource]);

  return imageSource ? (
    <div className={className}>
      <div className="flex items-center">
        <p className="body_r flex-shrink-0">
          {localizedText.captchaDescription}
        </p>
        <div className="flex-grow" />
        <Button
          className="refresh flex-shrink-0"
          tabIndex={-1}
          size="medium"
          onClick={() => onRefresh()}
        >
          <RefreshIcon />
        </Button>
      </div>
      <img alt="captcha_샘플" src={imgSrc} data-pin-nopin="true" />
      <input
        name="captcha"
        type="text"
        placeholder={localizedText.captchaPlaceholder}
        onChange={(e) => {
          onCaptchaTextChange(e.target.value);
        }}
        onKeyDown={handleOnKeyPress}
      />
    </div>
  ) : (
    <></>
  );
}

export default Captcha;
