import clsx from 'clsx';

import { TextFieldButton } from '../TextFieldButton/TextFieldButton';
import {
  InputInvisibleIcon,
  InputVisibleIcon,
} from '../../../../../icons/dop/24';

export interface Props {
  isVisible: boolean;
  onClick: (e: React.MouseEvent) => void;
}

export function VisibleButton({ isVisible, onClick }: Props) {
  return (
    <TextFieldButton
      className={clsx('visible', { on: isVisible, off: !isVisible })}
      onClick={onClick}
      title="보기"
    >
      <InputInvisibleIcon className="visible_off" />
      <InputVisibleIcon className="visible_on" />
    </TextFieldButton>
  );
}
