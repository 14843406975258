import { API_CAPTCHA_URL } from './constants';
import {
  CaptchaResponseData,
  CaptchaResultResponse,
  ResponseBody,
} from './types';

const CaptchaPrefix = 'data:image/png;base64,';

export async function getCaptchaImage(
  loginId: string,
  companyId: number,
): Promise<CaptchaResultResponse> {
  const res = await fetch(API_CAPTCHA_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.hostname,
    },
    body: JSON.stringify({ loginId, companyId }),
  });

  const result = (await res.json()) as ResponseBody<CaptchaResponseData>;

  if (res.ok) {
    return Promise.resolve({
      isSucceeded: true,
      captchaImage: CaptchaPrefix + result.data?.captchaImage,
    });
  }

  return Promise.resolve({
    isSucceeded: false,
    message: result.message,
  });
}
