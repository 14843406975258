import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { AccountSaveToggle } from '../AccountSaveToggle';
import { PasswordConfig } from '../PasswordConfig';

export function WebOptions() {
  const { t } = useTranslation('component');
  const localizedText = {
    saveAccount: t('login.form.saveAccount'),
    findPassword: t('login.form.findPassword'),
  };

  return (
    <div className="group left">
      <AccountSaveToggle title={localizedText.saveAccount} />
      <p className="divider dot"></p>
      <PasswordConfig />
    </div>
  );
}
