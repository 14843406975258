import { CheckBox } from '@dop-ui/react/shared/ui/check-box';
import { useContext } from '../../../Context';

export interface Props {
  title: string;
}

export function CompanySaveToggle({ title }: Props) {
  const { isCompanySaved, setIsCompanySaved } = useContext();

  return (
    <CheckBox
      id="company-save-checkbox"
      defaultChecked={isCompanySaved}
      onChange={(checked?: boolean) => {
        setIsCompanySaved(checked ?? false);
      }}
      className="mb-[18px]"
      label={title}
    />
  );
}

export default CompanySaveToggle;
