import * as React from 'react';
import { Provider } from 'jotai';
import { loginStore } from '../../stores/login-store';
import { ErrorDialogContainer } from '../../components/error-dialog-container';

const WrappedComponent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Provider store={loginStore}>
      <ErrorDialogContainer />
      <div className="page_login">
        <div className="wrap_login">{children}</div>
      </div>
    </Provider>
  );
};

export default React.memo(WrappedComponent);
