import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export function Body({ className, children }: PropsWithChildren<Props>) {
  return (
    <>
      {!className ? (
        <div className="wrap_form">{children}</div>
      ) : (
        <div className={className}>{children}</div>
      )}
    </>
  );
}
