export const API_LOGIN_URL = '/api/portal/public/auth/login';
export const API_CAPTCHA_URL = '/api/portal/public/auth/captcha';
export const API_COMPANY_URL = '/api/portal/public/auth/company';

// 로그인 에러 시 나올 수 있는 다이얼로그 타입
export const LOGIN_ERROR_ACCESS_DENIED_DIALOG =
  'Login.Error.PORTAL_0007.AccessDeniedDialog';
export const LOGIN_ERROR_MESSENGER_ACCESS_DENIED_DIALOG =
  'Login.Error.PORTAL_0018_Messenger.AccessDeniedDialog';
export const LOGIN_ERROR_LOGIN_DUPLICATED_DIALOG =
  'Login.Error.PORTAL_0013.LoginDuplicatedDialog';
export const LOGIN_ERROR_DORMANT_ACCOUNT_DIALOG =
  'Login.Error.PORTAL_0912.DormantAccountDialog';
