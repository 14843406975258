import { useToastMessage } from '@dop-ui/react/shared/ui/toast';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import * as Dialog from '@dop-ui/react/shared/ui/dialog';
import { useMutation } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useState } from 'react';
import { useContext } from '../../Context';
import { checkMailExistence } from './apis/mail-check';
import { sendPasswordResetMail } from './apis/send-password-reset-mail';
import { maskEmail } from './utils/mask-email';

export function PasswordResetDialog() {
  const { t } = useTranslation('common');
  const toaster = useToastMessage();
  const [isOpen, setIsOpen] = useState(false);
  const { company, id: loginId } = useContext();

  const [id, setId] = useState(loginId ?? '');
  const [errorMessage, setErrorMessage] = useState('');
  const [mail, setMail] = useState('');
  const [mailChecked, setMailChecked] = useState(false);

  const mailCheckMutation = useMutation({
    mutationFn: (info: { companyId: number; userId: string }) =>
      checkMailExistence(info.companyId, info.userId),
    onSuccess: (data) => {
      setMailChecked(true);
      if (!data || !data.isExistMail) {
        setMail('');
        setErrorMessage(t('dialog.pwreset.message.noMail'));
      } else if (data.isExistMail) {
        setMail(data.mailAddress ?? '');
        setErrorMessage('');
      }
    },
    onError: (error) => {
      toaster.warning(error.message);
      setMailChecked(false);
      setMail('');
      setErrorMessage('');
    },
  });

  const sendResetMailMutation = useMutation({
    mutationFn: (info: { companyId: number; userId: string }) =>
      sendPasswordResetMail(info.companyId, info.userId),
    onSuccess: () => {
      toaster.info(
        t('dialog.pwreset.message.sendSuccess', { email: maskEmail(mail) }),
      );
      setIsOpen(false);
    },
    onError: (error) => {
      toaster.warning(error.message);
    },
  });

  const onClickCheckMail = () => {
    if (!company) return;
    mailCheckMutation.mutate({ companyId: company.companyId, userId: id });
  };

  const onClickSendResetMail = () => {
    if (!company) return;
    sendResetMailMutation.mutate({
      companyId: company.companyId,
      userId: id,
    });
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger className="body_r">
        {t('dialog.pwreset.title')}
      </Dialog.Trigger>
      <Dialog.Content
        className="p-[24px]"
        showCloseButton={false}
        useOverlay
        useAutoClose={false}
      >
        <p className="leading-[30px] text-[20px] text-[#363636] //text-[--color-text-level1] font-[600]">
          {t('dialog.pwreset.title')}
        </p>
        <p className="mt-[16px] pr-[8px] leading-[21px] text-[14px] text-[#363636] //text-[--color-text-level1] font-[400]">
          {t('dialog.pwreset.description')}
        </p>
        <div className="flex items-start mt-[8px] gap-[10px]">
          <div className="flex-grow">
            <input
              className={clsx(
                'px-[12px] h-[40px] w-full border border-solid rounded-[8px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400] leading-[21px]',
                {
                  'border-[#D8D8D8]': !errorMessage,
                  'border-[#E84B4B]': errorMessage,
                },
              )}
              placeholder={t('dialog.pwreset.inputId')}
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
            {errorMessage && (
              <p className="mt-[8px] text-[#E84B4B] //text-[--color-text-caution] text-[13px] font-[400] whitespace-pre-wrap">
                {errorMessage}
              </p>
            )}
          </div>
          <Button
            size="medium"
            variant="solid"
            colorset="level2"
            shape="rect"
            disabled={!id}
            onClick={onClickCheckMail}
          >
            {t('dialog.pwreset.checkAccount.title')}
          </Button>
        </div>
        <div className="flex items-center mt-[16px] h-[80px] w-full border-t border-b border-solid border-[#F2F2F2]">
          <p className="w-[100px] text-[13px] text-[#5B5B5B] //text-[--color-text-level2]">
            {t('dialog.pwreset.registeredMail.title')}
          </p>
          <p
            className={clsx('px-[8px] text-[13px] font-[400]', {
              'text-[#999999] //text-[--color-text-level3]':
                !mailChecked || (mailChecked && mail.length === 0),
              'text-[#363636] //text-[--color-text-level1]':
                mailChecked && mail.length > 0,
            })}
          >
            {mail ? maskEmail(mail) : t('dialog.pwreset.showAfterCheck')}
          </p>
        </div>
        <div className="flex justify-end mt-[24px] gap-[16px]">
          <Button
            size="medium"
            variant="ghost"
            colorset="level2"
            shape="rect"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {t('dialog.cancel')}
          </Button>
          <Button
            size="medium"
            variant="solid"
            colorset="level1"
            shape="rect"
            disabled={mail.length === 0}
            onClick={onClickSendResetMail}
          >
            {t('dialog.pwreset.send.resetMail')}
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
