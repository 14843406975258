import {
  FORCE_PASSWORD_CHANGE_PAGE_URL,
  TFA_PAGE_URL,
} from '../../../constants';
import { LoginResultResponse, getCaptchaImage } from '../../../lib/login';
import { useContext } from '../Context';
import { useRouter } from 'next/navigation';
import { loginErrorHandlingInfoAtom } from '../stores/login-error-handling-info-atom';
import { useLoginStore } from '../stores/login-store';
import { setLocalStorageItem } from '@dop-ui/react/shared/lib/utils/local-storage';
import { SAVED_COMPANY_INFO_KEY } from '../model/constants';

export const useLoginResult = () => {
  const {
    id,
    company,
    isCompanySaved,
    setPassword,
    setCaptchaImageUrl,
    setCaptchaAnswer,
    setMessage,
    setValidation,
  } = useContext();
  const loginStore = useLoginStore();

  const router = useRouter();

  const handleLoginResultResponse = (
    result: LoginResultResponse,
    baseUrl: string | URL,
  ) => {
    if (result.isSucceeded) {
      router.push(baseUrl.toString());
      if (isCompanySaved)
        setLocalStorageItem({ key: SAVED_COMPANY_INFO_KEY, value: company });
      return;
    }

    setMessage(result.message ? result.message : '');

    if (result.errorHandlingInfo) {
      loginStore.set(loginErrorHandlingInfoAtom, {
        ...result.errorHandlingInfo,
        baseURL: baseUrl.toString(),
      });
      setMessage('');
      return;
    }
    setCaptchaAnswer('');
    setValidation(false);
    setPassword('');

    if (result.isForceChangePassword) {
      setLocalStorageItem({ key: SAVED_COMPANY_INFO_KEY, value: company });
      router.push(FORCE_PASSWORD_CHANGE_PAGE_URL);
    }
    if (result.isCaptchaNeeded) {
      setCaptchaImageUrl('');
      fetchCaptcha();
    }
    if (result.isTfaNeeded) {
      setMessage('');
      setLocalStorageItem({ key: SAVED_COMPANY_INFO_KEY, value: company });
      const query = new URLSearchParams();
      query.set('nextUrl', baseUrl.toString());
      if (company.loginImagePath) query.set('logoImg', company.loginImagePath);
      router.push(TFA_PAGE_URL + '?' + query.toString());
    }
  };

  const fetchCaptcha = () => {
    void getCaptchaImage(id, company?.companyId ?? 0)
      .then((result) => {
        if (result.isSucceeded && result.captchaImage) {
          setCaptchaImageUrl(result.captchaImage);
        }
      })
      .catch((error) => {
        console.log(`[Error] Captcha : ${error}`);
      });
  };

  return { handleLoginResultResponse, fetchCaptcha };
};
