import { CheckBox } from '@daouoffice/ui/lib/foundation/CheckBox/CheckBox';
import { useContext } from '../../../Context';

export interface Props {
  title: string;
}

export function AccountSaveToggle({ title }: Props) {
  const { savedAccountInfo, setSavedAccountInfo } = useContext();

  return (
    <CheckBox
      id="check01"
      defaultChecked={savedAccountInfo}
      onChange={(checked?: boolean) => {
        setSavedAccountInfo(checked ?? false);
      }}
      label={title}
    />
  );
}

export default AccountSaveToggle;
