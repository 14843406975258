import { Button } from '@dop-ui/react/shared/ui/button';

export interface Props {
  className?: string;
  title: string;
  handleLogin: (e?: React.MouseEvent<HTMLElement>) => void;
}

export function LoginButton({ className, title, handleLogin }: Props) {
  return (
    <Button
      type="submit"
      shape="rect"
      size="large"
      colorset="level1"
      variant="solid"
      autosize="full"
      tabIndex={0}
      onClick={handleLogin}
      className={className}
    >
      {title}
    </Button>
  );
}

export default LoginButton;
