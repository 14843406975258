'use client';
import * as Form from '@radix-ui/react-form';
import {
  KeyboardEvent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TooltipDirection } from '../../Tooltip/TooltipWith';
import { LabelTooltip } from './LabelTooltip';
import TextField from './TextField';
import { ClearButton } from './TextField/ClearButton/ClearButton';
import { VisibleButton } from './TextField/VisibleButton/VisibleButton';

interface Props {
  className?: string;
  name: string;
  label: string;
  labelLocation?: 'top' | 'inside';
  value: string;
  type: 'text' | 'password';
  useVisibleBtn?: boolean;
  useClearBtn?: boolean;
  placeholder?: string;
  tooltipText?: string;
  isValid?: boolean;
  tooltipDirection?: TooltipDirection;
  onTextChange: (text: string) => void;
  onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}

/**
 *
 * @param name: Radix Field의 name 속성 (고유값)
 * @param label: label 이름
 * @param labelLocation: Label의 위치 inside, top 중 선택 (default = inside), 기준: input tag
 * @param value: input tag의 value 값
 * @param type: input type (text, password)
 * @param tooltipText: label 옆에 툴팁으로 표시할 텍스트 (label이 top일 때만 사용)
 * @param tooltipDirection: 툴팁 표시 방향 (default = right)
 * @param useVisibleBtn: 비밀번호 보이기 버튼 사용 여부
 * @param useClearBtn: 입력된 input 지우는 Clear 버튼 사용 여부
 * @param placeholder: input tag의 placeholder 값
 * @param onTextChange: input text 변경 시 호출되는 함수
 * @param onKeyPress: input에서 키 입력 시 호출되는 함수
 * @param onPaste: input에서 붙여넣기 시 호출되는 함수
 * @param isValid: validation 체크
 * @returns
 */

export function Field({
  name,
  label,
  labelLocation = 'inside',
  value,
  type,
  useVisibleBtn = false,
  useClearBtn = false,
  placeholder,
  tooltipText = '',
  tooltipDirection = 'right',
  isValid = true,
  onTextChange,
  onKeyPress,
  onPaste,
}: PropsWithChildren<Props>) {
  const titleProps = labelLocation === 'inside' ? { title: label } : {};
  const [isVisible, setIsVisible] = useState(true);
  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (type === 'password') setIsVisible(false);
  }, []);

  const handleClearBtnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onTextChange('');
  };

  const handleVisibleBtnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsVisible(!isVisible);
  };

  return (
    <Form.Field name={name}>
      {labelLocation === 'top' && (
        <div className="pb-2 pl-1 flex flex-row content-center">
          <Form.Label className="font-semibold">{label}</Form.Label>
          {tooltipText && (
            <LabelTooltip
              tooltipText={tooltipText}
              tooltipDirection={tooltipDirection}
            />
          )}
        </div>
      )}
      <Form.Control asChild>
        <TextField
          {...titleProps}
          value={value}
          type={type}
          defaultValidState={isValid}
          labelLocation={labelLocation}
          placeholder={placeholder}
          ref={textFieldRef}
          visibleCondition={() => isVisible}
          onTextChange={onTextChange}
          onKeyPress={onKeyPress}
          onPaste={onPaste}
        >
          {useClearBtn && <ClearButton onClick={handleClearBtnClick} />}
          {useVisibleBtn && (
            <VisibleButton
              isVisible={isVisible}
              onClick={handleVisibleBtnClick}
            />
          )}
        </TextField>
      </Form.Control>
    </Form.Field>
  );
}
