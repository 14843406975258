export function maskEmail(email: string): string {
  const [localPart, domain] = email.split('@');

  if (!localPart) return email;

  let replaceLength = localPart.length / 2;
  replaceLength = replaceLength >= 4 ? 4 : replaceLength;

  const maskedLocalPart =
    localPart.length > 4
      ? localPart.slice(0, replaceLength) +
        '*'.repeat(localPart.length - replaceLength)
      : localPart;

  return `${maskedLocalPart}@${domain}`;
}
