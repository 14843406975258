import { Select } from '@daouoffice/ui';
import { EarthIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useEffect } from 'react';
import { useContext } from '../../Context';
import { LanguageType } from '../../types';

export function LanguageSelector() {
  const { setSelectedLanguage } = useContext();
  const { i18n } = useTranslation();
  const languages = [
    {
      title: '한국어',
      value: LanguageType.ko,
    },
    {
      title: 'English',
      value: LanguageType.en,
    },
    {
      title: '中文简体',
      value: LanguageType.zhCN,
    },
    {
      title: '中文繁體',
      value: LanguageType.zhTW,
    },
    {
      title: '日本語',
      value: LanguageType.ja,
    },
    {
      title: 'tiếng Việt',
      value: LanguageType.vi,
    },
  ];

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <div className="group right">
      <Select
        className="!bg-transparent !text-black"
        selectType="system"
        defaultOption={languages.find(
          (lang) => lang.value === (i18n.language as LanguageType),
        )}
        options={languages}
        onChangeValue={(value: string) => {
          setSelectedLanguage(value as LanguageType);
          i18n.changeLanguage(value).catch(console.error);
        }}
      >
        <EarthIcon />
      </Select>
    </div>
  );
}

export default LanguageSelector;
