import { MailInfo, ResponseBody } from '../types';

export async function checkMailExistence(
  companyId: number,
  loginId: string,
): Promise<MailInfo> {
  const query = new URLSearchParams({
    companyId: companyId.toString(),
    loginId,
  });

  const response = await fetch(
    '/api/portal/public/password-init/mail?' + query.toString(),
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
    },
  );

  const responseBody = (await response.json()) as ResponseBody;
  if (response.ok) return responseBody.data ?? { isExistMail: false };

  throw new Error(responseBody.message);
}
