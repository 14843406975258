import { DialogApiResult } from '../../types';

export async function releaseDormantAccount(): Promise<DialogApiResult> {
  const response = await fetch(
    '/api/portal/common/auth/status/dormant/release',
    {
      method: 'PUT',
      headers: {
        'X-Referer-Info': window.location.host,
      },
    },
  );

  if (!response.ok) {
    const responseData = (await response.json()) as {
      code: string;
      message: string;
    };
    return { success: false, ...responseData };
  }

  return { success: true };
}
