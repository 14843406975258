'use client';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { login } from '../../../../lib/login';
import { useContext } from '../../Context';
import { useLoginResult } from '../../hooks/useLoginResult';
import { LoginForm } from '../../index';
import WrappedComponent from '../../parts/Root/WrappedComponent';

interface Props {
  baseUrl: string | URL;
}

export function WebLoginClient({ baseUrl }: Props) {
  const { t } = useTranslation('component');
  const localizedText = {
    id: t('login.form.id'),
    password: t('login.form.password'),
    failMessage: t('login.form.failMessage'),
    companySelectorTitle: t('login.form.company.title'),
    saveCompany: t('login.form.company.save.company'),
    companyNotFound: t('login.form.company.not.found'),
    loginText: t('login.form.loginText'),
    description: t('login.form.description'),
  };
  const {
    id,
    password,
    company,
    captchaImageUrl,
    captchaAnswer,
    isValid,
    message,
    setId,
    setPassword,
    setCaptchaAnswer,
  } = useContext();
  const { handleLoginResultResponse, fetchCaptcha } = useLoginResult();
  const handleOnKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      formAction();
    }
  };

  const formAction = (e?: React.MouseEvent<HTMLElement>) => {
    e !== undefined && e.preventDefault();
    if (captchaImageUrl && captchaAnswer === '') {
      return;
    }

    void login(id, password, company?.companyId ?? 0, captchaAnswer).then(
      (response) => {
        if (!response.isSucceeded) {
          console.log(`[Error] Login : ${response.message}`);
          handleLoginResultResponse(
            {
              ...response,
              message: response.message
                ? response.message
                : localizedText.failMessage,
            },
            baseUrl,
          );
          return;
        }

        handleLoginResultResponse(response, baseUrl);
      },
    );
  };

  return (
    <LoginForm.Root WrappedComponent={WrappedComponent}>
      <LoginForm.Header>
        <LoginForm.Logo size={60} company={company} />
      </LoginForm.Header>

      <LoginForm.Body>
        <LoginForm.CompanySelector
          title={localizedText.companySelectorTitle}
          companyAutoSaverText={localizedText.saveCompany}
          companyNotFound={localizedText.companyNotFound}
        />
        <LoginForm.Field
          name={id}
          label={localizedText.id}
          type="text"
          value={id}
          useClearBtn={true}
          onKeyPress={handleOnKeyPress}
          onTextChange={setId}
        />
        <LoginForm.Field
          name={password}
          isValid={isValid}
          label={localizedText.password}
          type="password"
          value={password}
          useVisibleBtn={true}
          useClearBtn={true}
          onKeyPress={handleOnKeyPress}
          onTextChange={setPassword}
        />
        <LoginForm.Message isValid={isValid} message={message} />
        <LoginForm.Captcha
          className="captchaContents"
          imageSource={captchaImageUrl}
          handleOnKeyPress={handleOnKeyPress}
          onRefresh={fetchCaptcha}
          onCaptchaTextChange={setCaptchaAnswer}
        />
        <LoginForm.Submit asChild>
          <LoginForm.LoginButton
            className="mb-4"
            title={localizedText.loginText}
            handleLogin={formAction}
          />
        </LoginForm.Submit>
        <LoginForm.Options type="web" />
      </LoginForm.Body>
    </LoginForm.Root>
  );
}
