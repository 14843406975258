export type LoginInputType = 'id' | 'password';

export type InputButtonType = 'clear' | 'hideAndShow';

export enum LanguageType {
  ko = 'ko',
  en = 'en',
  zhCN = 'zh-cn',
  zhTW = 'zh-tw',
  ja = 'ja',
  vi = 'vi',
}
