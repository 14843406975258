'use client';

import { PropsWithChildren, useEffect } from 'react';

interface Props {
  browserTitle?: string;
  faviconImageURL?: string;
}

export function FaviconTitleProvider({
  browserTitle,
  faviconImageURL,
  children,
}: PropsWithChildren<Props>) {
  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;

    if (link) {
      link.href = faviconImageURL || '/favicon.ico';
    } else {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = faviconImageURL || '/favicon.ico';
      document.head.appendChild(newLink);
    }
  }, [faviconImageURL]);

  return (
    <>
      {browserTitle && <title>{browserTitle}</title>}
      {children}
    </>
  );
}
