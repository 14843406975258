import { ServiceLogo } from '@daouoffice/ui/lib/service/ServiceLogo/ServiceLogo';
import { CompanyInfo } from '../../../../lib/login';

export interface Props {
  size: number;
  company?: CompanyInfo;
}

export function Logo({ size, company }: Props) {
  return (
    <div className="flex justify-center items-center w-[400px] h-[200px]">
      <ServiceLogo size={size} logoImagePath={company?.loginImagePath} />
    </div>
  );
}
