import { useAtom } from 'jotai';
import { loginErrorHandlingInfoAtom } from '../../stores/login-error-handling-info-atom';
import {
  LOGIN_ERROR_ACCESS_DENIED_DIALOG,
  LOGIN_ERROR_DORMANT_ACCOUNT_DIALOG,
  LOGIN_ERROR_LOGIN_DUPLICATED_DIALOG,
  LOGIN_ERROR_MESSENGER_ACCESS_DENIED_DIALOG,
} from '../../../../lib/login/constants';
import {
  AccessDeniedDialog,
  MessengerNotAccessableDialog,
  LoginDuplicatedDialog,
} from '../login-error-dialogs';
import { useContext } from '../../Context';
import { DormantAccountDialog } from '../login-error-dialogs/domant-account-dialog';

export function ErrorDialogContainer() {
  const [loginErrorDialogInfo, setLoginErrorDialogInfo] = useAtom(
    loginErrorHandlingInfoAtom,
  );

  const {
    id,
    password,
    company,
    captchaAnswer,
    setPassword,
    setCaptchaAnswer,
    setValidation,
  } = useContext();

  const onCloseDialog = () => {
    setLoginErrorDialogInfo({});
    setPassword('');
    setCaptchaAnswer('');
    setValidation(false);
  };

  const errorDialog = () => {
    if (!loginErrorDialogInfo) return;
    switch (loginErrorDialogInfo.type) {
      case LOGIN_ERROR_ACCESS_DENIED_DIALOG:
        return (
          <AccessDeniedDialog
            onCloseDialog={onCloseDialog}
            data={loginErrorDialogInfo.data}
          />
        );
      case LOGIN_ERROR_MESSENGER_ACCESS_DENIED_DIALOG:
        return <MessengerNotAccessableDialog onCloseDialog={onCloseDialog} />;
      case LOGIN_ERROR_LOGIN_DUPLICATED_DIALOG:
        return (
          <LoginDuplicatedDialog
            baseURL={loginErrorDialogInfo.baseURL ?? '/'}
            onCloseDialog={onCloseDialog}
            data={loginErrorDialogInfo.data}
            loginInfo={{
              loginId: id,
              password,
              companyId: company?.companyId ?? 0,
              captcha: captchaAnswer,
            }}
          />
        );
      case LOGIN_ERROR_DORMANT_ACCOUNT_DIALOG:
        return (
          <DormantAccountDialog
            onCloseDialog={onCloseDialog}
            data={loginErrorDialogInfo.data}
          />
        );
    }

    return <></>;
  };

  return <>{errorDialog()}</>;
}
