export const LOGIN_ERROR = {
  PORTAL_0001: 'PORTAL-0001', // ID, PW 입력 오류
  PORTAL_0003: 'PORTAL-0003', // 계정 잠김
  PORTAL_0004: 'PORTAL-0004', // 비밀번호 오류
  PORTAL_0005: 'PORTAL-0005', // 캡차정보 없음 오류
  PORTAL_0007: 'PORTAL-0007', // 서비스 접근 불가
  PORTAL_0008: 'PORTAL-0008', // 다우오피스 사용 불가 오류
  PORTAL_0009: 'PORTAL-0009', // 다우오피스 사용 만료 오류
  PORTAL_0010: 'PORTAL-0010', // 중지 계정
  PORTAL_0011: 'PORTAL-0011', // 삭제 계정
  PORTAL_0012: 'PORTAL-0012', // 계정정보 or 자동 입력 방지 문자 입력 오류
  PORTAL_0013: 'PORTAL-0013', // 동일 계정 접근 알림
  PORTAL_0015: 'PORTAL-0015', // 모바일 앱 접근 제한 오류
  PORTAL_0016: 'PORTAL-0016', // 모바일 웹 접근 제한, 로그인 불가
  PORTAL_0017: 'PORTAL-0017', // 사용자에 의한 앱 차단
  PORTAL_0018: 'PORTAL-0018', // PC 메신저 기능 제한
  PORTAL_0022: 'PORTAL-0022',
  PORTAL_0023: 'PORTAL-0023', // 모바일 기기 등록 필요 오류
  PORTAL_0901: 'PORTAL-0901', // 비밀번호 강제 변경 필요 알림
  PORTAL_0902: 'PORTAL-0902', // 2차 인증 필요 알림
  PORTAL_0912: 'PORTAL-0912', // 휴면 계정
};

export const COMMON_ERROR = {
  COMM_0008: 'COMM-0008', // 존재하지 않는 계정
};
