import { getRandomColor } from '../../lib/utils/color';
import { RadixAvatar } from './radix-avatar';
import { SquirecleAvatar } from './squirecle-avatar';
import { AvatarShapeTypes } from './constants';
import type { CommonProps } from './types';

export interface Props extends CommonProps {
  type?: Lowercase<keyof typeof AvatarShapeTypes>;
}

const MAX_ALT_LENGTH = 3;

/**
 * Avatar 컴포넌트
 * @returns
 */
export function Avatar({
  type = AvatarShapeTypes.Squircle,
  backgroundColor,
  altLength = 1,
  ...props
}: Props) {
  const fixedAltLength = Math.min(altLength, MAX_ALT_LENGTH);
  const bgColor = backgroundColor || getRandomColor();
  const Comp =
    type === AvatarShapeTypes.Squircle ? SquirecleAvatar : RadixAvatar;
  const mergedProps = {
    ...props,
    backgroundColor: bgColor,
    type: type === AvatarShapeTypes.Squircle ? undefined : type,
    altLength: fixedAltLength,
  };

  return <Comp {...mergedProps} />;
}

export default Avatar;
