import { clsx } from 'clsx';
import React, {
  forwardRef,
  KeyboardEvent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

export type TextFieldType = 'text' | 'password';
export type LabelLocationType = 'top' | 'inside';

export interface Props {
  title?: string;
  value?: string;
  placeholder?: string;
  type: TextFieldType;
  labelLocation?: LabelLocationType;
  disabled?: boolean;
  defaultValidState?: boolean;
  children?: React.ReactNode;
  visibleCondition: () => boolean;
  onTextChange?: (text: string) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}

const TextField = forwardRef<HTMLInputElement, Props>(function TextField(
  {
    title,
    value,
    placeholder,
    type,
    labelLocation = 'inside',
    disabled = false,
    defaultValidState = true,
    children,
    visibleCondition,
    onTextChange,
    onKeyPress,
    onPaste,
  }: PropsWithChildren<Props>,
  ref,
) {
  const [isActive, setIsActive] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isValid, setValidState] = useState(defaultValidState);

  useEffect(() => {
    if (value && value !== '' && labelLocation === 'inside') setIsActive(true);
    else setIsActive(false);
  }, [value]);

  useEffect(() => {
    setValidState(defaultValidState);
  }, [defaultValidState]);

  return (
    <div
      className={clsx('group_input', {
        active: isActive,
        focused: isFocused,
      })}
    >
      <label className="label">{title}</label>
      <div
        className={clsx('input_basic', {
          '!border-[#E84B4B]': !isValid,
        })}
      >
        <input
          className={clsx('input_txt', { password: type === 'password' })}
          value={value}
          placeholder={placeholder}
          type={visibleCondition() ? 'text' : 'password'}
          autoComplete="on"
          ref={ref}
          disabled={disabled}
          onBlur={() => {
            labelLocation === 'inside' && setIsFocused(false);
          }}
          onFocus={() => {
            labelLocation === 'inside' && setIsFocused(true);
          }}
          onChange={(e) => {
            if (onTextChange) onTextChange(e.target.value);
          }}
          onKeyDown={onKeyPress}
          onPaste={onPaste}
        />
        {children}
      </div>
    </div>
  );
});

export default TextField;
