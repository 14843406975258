import { useState } from 'react';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { NoticeDialog } from '../notice-dialog';
import { LoginErrorDialogProps } from '../types';

export function MessengerNotAccessableDialog({
  onCloseDialog,
}: LoginErrorDialogProps) {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(true);

  return (
    <NoticeDialog
      title={t('dialog.messenger.accessDeny.title')}
      open={isOpen}
      onOpenChange={setIsOpen}
      onCloseDialog={onCloseDialog}
    >
      <p className="mt-[16px] text-center text-[#363636] //text-[--color-text-level1] text-[14px] font-[400] whitespace-pre-wrap">
        {t('dialog.messenger.accessDeny.description')}
      </p>
      <Button
        className="mt-[24px]"
        shape="rect"
        size="large"
        autosize="full"
        variant="solid"
        colorset="level1"
        onClick={() => {
          setIsOpen(false);
          onCloseDialog();
        }}
      >
        {t('dialog.confirm')}
      </Button>
    </NoticeDialog>
  );
}
