import { TooltipWith } from '../../Tooltip';
import { TooltipDirection } from '../../Tooltip/TooltipWith';

interface Props {
  tooltipText?: string;
  tooltipDirection?: TooltipDirection;
}

export function LabelTooltip({
  tooltipText = '',
  tooltipDirection = 'top',
}: Props) {
  return (
    <TooltipWith
      text={tooltipText}
      direction={tooltipDirection}
      className="pl-1 whitespace-pre-wrap"
    >
      <i className="icon ic_small">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 3.25C8.20979 3.25 6.4929 3.96116 5.22703 5.22703C3.96116 6.4929 3.25 8.20979 3.25 10C3.25 10.8864 3.42459 11.7642 3.76381 12.5831C4.10303 13.4021 4.60023 14.1462 5.22703 14.773C5.85383 15.3998 6.59794 15.897 7.41689 16.2362C8.23583 16.5754 9.11358 16.75 10 16.75C10.8864 16.75 11.7642 16.5754 12.5831 16.2362C13.4021 15.897 14.1462 15.3998 14.773 14.773C15.3998 14.1462 15.897 13.4021 16.2362 12.5831C16.5754 11.7642 16.75 10.8864 16.75 10C16.75 8.20979 16.0388 6.4929 14.773 5.22703C13.5071 3.96116 11.7902 3.25 10 3.25ZM4.16637 4.16637C5.71354 2.61919 7.81196 1.75 10 1.75C12.188 1.75 14.2865 2.61919 15.8336 4.16637C17.3808 5.71354 18.25 7.81196 18.25 10C18.25 11.0834 18.0366 12.1562 17.622 13.1571C17.2074 14.1581 16.5997 15.0675 15.8336 15.8336C15.0675 16.5997 14.1581 17.2074 13.1571 17.622C12.1562 18.0366 11.0834 18.25 10 18.25C8.9166 18.25 7.8438 18.0366 6.84286 17.622C5.84193 17.2074 4.93245 16.5997 4.16637 15.8336C3.40029 15.0675 2.7926 14.1581 2.37799 13.1571C1.96339 12.1562 1.75 11.0834 1.75 10C1.75 7.81196 2.61919 5.71354 4.16637 4.16637ZM10 6.75C10.4142 6.75 10.75 7.08579 10.75 7.5V10.625C10.75 11.0392 10.4142 11.375 10 11.375C9.58579 11.375 9.25 11.0392 9.25 10.625V7.5C9.25 7.08579 9.58579 6.75 10 6.75ZM9.25 13.125C9.25 12.7108 9.58579 12.375 10 12.375H10.0067C10.4209 12.375 10.7567 12.7108 10.7567 13.125V13.1317C10.7567 13.5459 10.4209 13.8817 10.0067 13.8817H10C9.58579 13.8817 9.25 13.5459 9.25 13.1317V13.125Z"
            fill="#AAAAAA"
          />
        </svg>
      </i>
    </TooltipWith>
  );
}
