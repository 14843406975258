import { API_COMPANY_URL } from './constants';
import { CompanyInfo } from './types';

export interface ResponseBody {
  code?: string;
  name?: string;
  message?: string;
  data?: {
    companyList: CompanyInfo[];
  };
}

export async function getCompanyList(referer?: string): Promise<ResponseBody> {
  try {
    const resp = await fetch(API_COMPANY_URL, {
      method: 'GET',
      headers: {
        'X-Referer-Info': referer ?? window.location.hostname,
      },
    });

    const data = (await resp.json()) as ResponseBody;

    return data ?? {};
  } catch (e) {
    console.error(e);
    const error = e as ResponseBody;
    return error;
  }
}
