import * as FormPrimitives from '@radix-ui/react-form';
import { ComponentType } from 'react';

export const BaseRoot = FormPrimitives.Root;
export const Field = FormPrimitives.Field;
export type FieldProps = FormPrimitives.FormFieldProps;
export const Label = FormPrimitives.Label;
export type LabelProps = FormPrimitives.FormLabelProps;
export const Control = FormPrimitives.Control;
export type ControlProps = FormPrimitives.FormControlProps;
export const Submit = FormPrimitives.Submit;
export type SubmitProps = FormPrimitives.FormSubmitProps;

interface Props {
  WrappedComponent: ComponentType<{ children: React.ReactNode }>;
  children: React.ReactNode;
}

/**
 *
 * @param WrappedComponent: Form Root를 감싸는 컴포넌트 (ex. css format 맞출 때 사용)
 * @param children: Form Root 안에 들어갈 children
 * @returns
 */
export function Root({ WrappedComponent, children }: Props) {
  return (
    <BaseRoot>
      <WrappedComponent>{children}</WrappedComponent>
    </BaseRoot>
  );
}
