import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { formatDate } from '@dop-ui/react/shared/lib/date';
import { useToastMessage } from '@dop-ui/react/shared/ui/toast';
import { formatDateWithLocale } from '@dop-ui/react/shared/lib/date/with-locale';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { NoticeDialog } from '../notice-dialog';
import { LoginErrorDialogProps } from '../types';
import { releaseDormantAccount } from './apis/release-dormant';
import { LOGIN_ERROR } from '../../../../../lib/login/errors';
import {
  FORCE_PASSWORD_CHANGE_PAGE_URL,
  ServiceErrorCodes,
  TFA_PAGE_URL,
} from '../../../../../constants';

export function DormantAccountDialog({
  data,
  onCloseDialog,
}: LoginErrorDialogProps) {
  const { t, i18n } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(true);

  const router = useRouter();
  const { dormantDate } = data as { dormantDate: string };

  const loginDate = formatDateWithLocale({
    date: dormantDate,
    format: `YYYY.MM.DD`,
    locale: i18n.language,
  });

  const toaster = useToastMessage();

  const releaseDormant = async () => {
    const result = await releaseDormantAccount();
    if (result.success) {
      toaster.info(
        formatDate({ date: new Date(), format: 'YYYY.MM.DD.' }) +
          t('dialog.dormantAccount.release.complete'),
      );
      setIsOpen(false);
      onCloseDialog();
    } else if (
      result.code === LOGIN_ERROR.PORTAL_0901 ||
      result.code === ServiceErrorCodes['ROUTE-0901'].code
    ) {
      router.replace(FORCE_PASSWORD_CHANGE_PAGE_URL);
    } else if (
      result.code === LOGIN_ERROR.PORTAL_0902 ||
      result.code === ServiceErrorCodes['ROUTE-0902'].code
    ) {
      router.replace(TFA_PAGE_URL);
    } else {
      toaster.warning(result.message ?? 'Error');
    }
  };

  const onClickRelease = () => {
    void releaseDormant();
  };

  return (
    <NoticeDialog
      title={t('dialog.dormantAccount.title')}
      open={isOpen}
      showCloseButton={false}
      onOpenChange={setIsOpen}
      onCloseDialog={onCloseDialog}
    >
      <p className="mt-[16px] text-center text-[#363636] //text-[--color-text-level1] text-[14px] font-[400] whitespace-pre-wrap">
        {t('dialog.dormantAccount.description')}
      </p>
      <div className="w-full mt-[24px] py-[32px] rounded-[16px] bg-[#F2F2F2]">
        <p className="w-full text-center text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
          {t('dialog.dormantAccount.lastDormantDate')}
        </p>
        <p className="mt-[16px] w-full text-center text-[#363636] //text-[--color-text-level1] text-[24px] font-[500]">
          {loginDate}
        </p>
      </div>
      <p className="mt-[24px] text-center text-[#363636] //text-[--color-text-level1] text-[14px] font-[400] whitespace-pre-wrap">
        {t('dialog.dormantAccount.description2')}
      </p>
      <Button
        className="mt-[24px]"
        shape="rect"
        size="large"
        autosize="full"
        variant="solid"
        colorset="level1"
        onClick={onClickRelease}
      >
        {t('dialog.dormantAccount.release')}
      </Button>
    </NoticeDialog>
  );
}
