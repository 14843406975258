import { DialogApiResult } from '../../types';

export async function clearDuplicationAndLogin(
  loginId: string,
  password: string,
  companyId: number,
  captcha: string = '',
): Promise<DialogApiResult> {
  const response = await fetch(
    '/api/portal/public/auth/duplicate/block/login',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ loginId, password, companyId, captcha }),
    },
  );

  if (!response.ok) {
    const responseData = (await response.json()) as {
      code: string;
      message: string;
    };

    return { success: false, ...responseData };
  }

  return { success: true };
}
