import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { NoticeDialog } from '../notice-dialog';
import { clearDuplicationAndLogin } from './apis/clear-duplication-and-login';
import { LoginErrorDialogProps } from '../types';
import { LOGIN_ERROR } from '../../../../../lib/login';
import {
  FORCE_PASSWORD_CHANGE_PAGE_URL,
  ServiceErrorCodes,
  TFA_PAGE_URL,
} from '../../../../../constants';
import { useToastMessage } from '@dop-ui/react/shared/ui/toast';

interface DeniedAccessInfo {
  duplicatedIpList: string[];
  device: string;
}

export interface Props extends LoginErrorDialogProps {
  baseURL: string;
  loginInfo: {
    loginId: string;
    password: string;
    captcha?: string;
    companyId: number;
  };
}

export function LoginDuplicatedDialog({
  baseURL,
  loginInfo,
  data,
  onCloseDialog,
}: Props) {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(true);

  const router = useRouter();
  const toaster = useToastMessage();

  const clearAndLogin = async () => {
    const result = await clearDuplicationAndLogin(
      loginInfo.loginId,
      loginInfo.password,
      loginInfo.companyId,
      loginInfo.captcha,
    );

    if (result.success) {
      router.replace(baseURL);
    } else if (
      result.code === LOGIN_ERROR.PORTAL_0901 ||
      result.code === ServiceErrorCodes['ROUTE-0901'].code
    ) {
      router.replace(FORCE_PASSWORD_CHANGE_PAGE_URL);
    } else if (
      result.code === LOGIN_ERROR.PORTAL_0902 ||
      result.code === ServiceErrorCodes['ROUTE-0902'].code
    ) {
      router.replace(TFA_PAGE_URL);
    } else {
      toaster.warning(result.message ?? 'Error');
    }
  };

  if (!data) {
    return;
  }

  const { duplicatedIpList, device } = data as DeniedAccessInfo;

  if (!duplicatedIpList || !device) {
    return;
  }

  return (
    <NoticeDialog
      title={t('dialog.duplicateLogin.title')}
      open={isOpen}
      onOpenChange={setIsOpen}
      onCloseDialog={onCloseDialog}
    >
      <p className="mt-[8px] w-full text-[#363636] //text-[--color-text-level1] text-[13px] font-[400] text-center whitespace-pre-wrap">
        {t('dialog.duplicateLogin.description')}
      </p>
      <div className="mt-[24px] py-[16px] px-[24px] w-full rounded-[8px] bg-[#F2F2F2] //bg-[--color-bg-level4]">
        <div className="flex py-[4px] gap-[8px]">
          <p className="w-[100px] text-[#5B5B5B] //text-[--color-text-level2] text-[13px] font-[400] flex-shrink-0">
            {t('dialog.accessDeny.ip')}
          </p>
          <p className="text-[#363636] //text-[--color-text-level1] text-[13px] font-[400]">
            {duplicatedIpList.join(', ')}
          </p>
        </div>
        <div className="flex py-[4px] gap-[8px]">
          <p className="w-[100px] text-[#5B5B5B] //text-[--color-text-level2] text-[13px] font-[400] flex-shrink-0">
            {t('dialog.accessDeny.device')}
          </p>
          <p className="text-[#363636] //text-[--color-text-level1] text-[13px] font-[400]">
            {device}
          </p>
        </div>
      </div>
      <Button
        className="mt-[24px]"
        shape="rect"
        size="large"
        variant="solid"
        autosize="full"
        colorset="level1"
        onClick={() => void clearAndLogin()}
      >
        {t('dialog.duplicateLogin.endConnection')}
      </Button>
    </NoticeDialog>
  );
}
