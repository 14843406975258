import { ClearIcon } from '../../../../../icons/dop/24';
import { TextFieldButton } from '../TextFieldButton/TextFieldButton';

export interface Props {
  onClick: (e: React.MouseEvent) => void;
}

export function ClearButton({ onClick }: Props) {
  return (
    <TextFieldButton className="clear" onClick={onClick} title="초기화">
      <ClearIcon />
    </TextFieldButton>
  );
}
