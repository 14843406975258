interface Props {
  isValid: boolean;
  message: string;
}

export function Message({ isValid, message }: Props) {
  return (
    <>
      {!isValid && (
        <p className="pb-[16px] //pb-[--Space-Large] font-noraml leading-[19.5px] -tracking-[0.52px] text-[#E84B4B] text-[13px]">
          {message}
        </p>
      )}
    </>
  );
}

export default Message;
