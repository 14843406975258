import {
  API_LOGIN_URL,
  LOGIN_ERROR_ACCESS_DENIED_DIALOG,
  LOGIN_ERROR_DORMANT_ACCOUNT_DIALOG,
  LOGIN_ERROR_LOGIN_DUPLICATED_DIALOG,
  LOGIN_ERROR_MESSENGER_ACCESS_DENIED_DIALOG,
} from './constants';
import { LOGIN_ERROR } from './errors';
import { LoginResultResponse, ResponseBody } from './types';

interface DeviceInfo {
  deviceId: string;
  deviceModel: string;
  osType: string;
  osVersion: string;
}

function parseUserAgent(userAgent: string): DeviceInfo {
  // TODO: 백엔드 모바일 협의 후 device ID 정의 또는 삭제 필요, 아직 미정
  let deviceId = 'Unknown';
  let deviceModel = 'Unknown';
  let osType = 'Unknown';
  let osVersion = 'Unknown';

  const osPattern = /\(([^)]+)\)/;
  const osMatch = userAgent.match(osPattern);
  if (osMatch) {
    const osInfo = osMatch?.[1]?.split(';') || [];
    if (osInfo.length > 0) {
      osType = osInfo[0]?.trim() ?? 'Unknown';
      if (osInfo.length > 1) {
        osVersion = osInfo[1]?.trim() ?? 'Unknown';
      }
    }
  }

  const modelPattern =
    /(?:Android|iPhone|iPad|Windows Phone|Nexus)[^;]*;\s*([^\s;]+)/;
  const modelMatch = userAgent.match(modelPattern);
  if (modelMatch && modelMatch[1]) {
    console.log(modelMatch);
    deviceModel = modelMatch[1].trim();
  }

  deviceId = 'Not Available';
  return { deviceId, deviceModel, osType, osVersion };
}

export async function login(
  id: string,
  password: string,
  companyId: number,
  captchaAnswer: string = '',
  referer: string = '',
): Promise<LoginResultResponse> {
  const getBodyData = () => {
    if (window.navigator.userAgent.includes('Mobile')) {
      const deviceInfo = parseUserAgent(navigator.userAgent);
      return {
        companyId: companyId,
        loginId: id,
        password: password,
        captcha: captchaAnswer,
        ...deviceInfo,
      };
    } else
      return {
        companyId: companyId,
        loginId: id,
        password: password,
        captcha: captchaAnswer,
      };
  };

  const res = await fetch(API_LOGIN_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': referer,
    },
    body: JSON.stringify(getBodyData()),
  });
  const loginResponse = (await res.json()) as ResponseBody<{
    [key: string]: unknown;
  }>;

  if (res.ok) {
    return Promise.resolve({ isSucceeded: true });
  }

  switch (loginResponse.code) {
    case LOGIN_ERROR.PORTAL_0016:
      // TODO: - 모바일 로그인 제한에 해당하는 동작 작성
      return Promise.resolve({ isSucceeded: false });
    case LOGIN_ERROR.PORTAL_0005:
    case LOGIN_ERROR.PORTAL_0012:
      return Promise.resolve({
        isSucceeded: false,
        message: loginResponse.message,
        isCaptchaNeeded: true,
      });
    case LOGIN_ERROR.PORTAL_0007:
      return Promise.resolve({
        isSucceeded: false,
        errorHandlingInfo: {
          type: LOGIN_ERROR_ACCESS_DENIED_DIALOG,
          data: loginResponse.data,
        },
      });

    case LOGIN_ERROR.PORTAL_0013:
      return Promise.resolve({
        isSucceeded: false,
        errorHandlingInfo: {
          type: LOGIN_ERROR_LOGIN_DUPLICATED_DIALOG,
          data: loginResponse.data,
        },
      });
    case LOGIN_ERROR.PORTAL_0018:
      return Promise.resolve({
        isSucceeded: false,
        message: loginResponse.message,
        errorHandlingInfo: {
          type: LOGIN_ERROR_MESSENGER_ACCESS_DENIED_DIALOG,
        },
      });
    case LOGIN_ERROR.PORTAL_0901:
      return Promise.resolve({
        isSucceeded: false,
        isForceChangePassword: true,
      });
    // 2차 인증 필요
    case LOGIN_ERROR.PORTAL_0902:
      return Promise.resolve({
        isSucceeded: false,
        isTfaNeeded: true,
      });
    case LOGIN_ERROR.PORTAL_0912:
      return Promise.resolve({
        isSucceeded: false,
        errorHandlingInfo: {
          type: LOGIN_ERROR_DORMANT_ACCOUNT_DIALOG,
          data: loginResponse.data,
        },
      });
    default:
      return Promise.resolve({
        isSucceeded: false,
        message: loginResponse.message,
      });
  }
}
