import React, { PropsWithChildren } from 'react';
import { CloseIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { Button } from '@dop-ui/react/shared/ui/button';
import * as Dialog from '@dop-ui/react/shared/ui/dialog';

export interface Props extends Dialog.Primitives.DialogProps {
  icon?: React.ReactElement;
  title: string;
  useAutoClose?: boolean;
  showCloseButton?: boolean;
  onCloseDialog: () => void;
}

export function NoticeDialog({
  icon,
  title,
  useAutoClose = false,
  showCloseButton = true,
  children,
  onCloseDialog,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <Dialog.Root {...props}>
      <Dialog.Content
        className="p-[40px] flex flex-col items-center w-[500px]"
        useAutoClose={useAutoClose}
        showCloseButton={false}
        useOverlay
      >
        {showCloseButton && (
          <Button
            className="absolute top-[16px] right-[52px] size-[40px]"
            onClick={onCloseDialog}
          >
            <CloseIcon />
          </Button>
        )}
        <div className="size-[80px] p-[20px] rounded-[32px] bg-[#f7f9f9]">
          {icon ? (
            icon
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <g id="Frame">
                <path
                  id="Vector"
                  d="M30.6067 30.6066C33.4197 27.7936 35.0001 23.9782 35.0001 20C35.0001 16.0217 33.4197 12.2064 30.6067 9.3933C27.7936 6.58024 23.9783 4.99988 20 4.99988C16.0217 4.99988 12.2064 6.58024 9.39333 9.3933M30.6067 30.6066C27.7936 33.4197 23.9783 35.0001 20 35.0001C16.0217 35.0001 12.2064 33.4197 9.39333 30.6066C6.58027 27.7936 4.99991 23.9782 4.99991 20C4.99991 16.0217 6.58027 12.2064 9.39333 9.3933M30.6067 30.6066L9.39333 9.3933"
                  stroke="black"
                  strokeWidth="2.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          )}
        </div>
        <p className="leading-[36px] mt-[24px] text-[24px] text-[#363636] //text-[--color-text-level1] font-[500]">
          {title}
        </p>
        {children}
      </Dialog.Content>
    </Dialog.Root>
  );
}
