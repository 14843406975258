import { WebOptions } from './WebOptions/WebOptions';
import { ChatOptions } from './ChatOptions/ChatOptions';
import { LanguageSelector } from '../LanguageSelector';

interface Props {
  type: 'web' | 'chat';
}
export function Options({ type }: Props) {
  return (
    <div className="wrap_additional">
      {type === 'web' ? <WebOptions /> : <ChatOptions />}
      <LanguageSelector />
    </div>
  );
}

export default Options;
