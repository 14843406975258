export async function sendPasswordResetMail(
  companyId: number,
  loginId: string,
) {
  const response = await fetch('/api/portal/public/password-init/mail', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify({ companyId, loginId }),
  });

  if (!response.ok) {
    const responseData = (await response.json()) as { message: string };
    throw new Error(responseData.message);
  }
}
