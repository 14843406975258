'use client';
import * as React from 'react';
import { CompanyInfo } from '../../lib/login';
import { LanguageType } from './types';
import { getLocalStorageItem } from '@dop-ui/react/shared/lib/utils';
import { SAVED_COMPANY_INFO_KEY } from './model/constants';

export interface IContextValues {
  id: string;
  password: string;
  company: CompanyInfo;
  savedCompanyInfo?: CompanyInfo;
  isValid: boolean;
  message: string;
  captchaImageUrl: string;
  captchaAnswer: string;
  selectedLanguage: LanguageType;
  savedAccountInfo: boolean;
  isCompanySaved: boolean;
  useAutoLogin: boolean;
}

export interface IContextActions {
  setId: React.Dispatch<React.SetStateAction<string>>;
  setCompany: React.Dispatch<React.SetStateAction<CompanyInfo>>;
  setSavedCompanyInfo: React.Dispatch<
    React.SetStateAction<CompanyInfo | undefined>
  >;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setCaptchaImageUrl: React.Dispatch<React.SetStateAction<string>>;
  setCaptchaAnswer: React.Dispatch<React.SetStateAction<string>>;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<LanguageType>>;
  setSavedAccountInfo: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCompanySaved: React.Dispatch<React.SetStateAction<boolean>>;
  setUseAutoLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  id: '',
  password: '',
  company: {} as CompanyInfo,
  isValid: true,
  message: '',
  captchaImageUrl: '',
  captchaAnswer: '',
  selectedLanguage: LanguageType.ko,
  savedAccountInfo: false,
  isCompanySaved: false,
  useAutoLogin: false,
});

export const ActionsContext = React.createContext<IContextActions>({
  setId: () => undefined,
  setPassword: () => undefined,
  setCompany: () => undefined,
  setSavedCompanyInfo: () => undefined,
  setValidation: () => undefined,
  setMessage: () => undefined,
  setCaptchaImageUrl: () => undefined,
  setCaptchaAnswer: () => undefined,
  setSelectedLanguage: () => undefined,
  setSavedAccountInfo: () => undefined,
  setIsCompanySaved: () => undefined,
  setUseAutoLogin: () => undefined,
});

export function Provider({ children }: { children: React.ReactNode }) {
  const [id, setId] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [company, setCompany] = React.useState({} as CompanyInfo);
  const [isValid, setValidation] = React.useState(true);
  const [message, setMessage] = React.useState('');
  const [captchaImageUrl, setCaptchaImageUrl] = React.useState('');
  const [captchaAnswer, setCaptchaAnswer] = React.useState('');
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    LanguageType.ko,
  );
  const [savedAccountInfo, setSavedAccountInfo] =
    React.useState<boolean>(false);
  const [isCompanySaved, setIsCompanySaved] = React.useState<boolean>(false);
  const [useAutoLogin, setUseAutoLogin] = React.useState<boolean>(false);
  const [savedCompanyInfo, setSavedCompanyInfo] = React.useState<
    CompanyInfo | undefined
  >();

  React.useEffect(() => {
    const savedInfo = getLocalStorageItem<CompanyInfo>({
      key: SAVED_COMPANY_INFO_KEY,
      defaultValue: {
        companyId: -1,
        name: '',
        logoImagePath: '',
        loginImagePath: '',
        faviconImagePath: '',
        browserTitle: '',
      },
      type: 'object',
    });

    if (savedInfo.companyId !== -1) {
      setSavedCompanyInfo(savedInfo);
    }
  }, []);

  const contextValues: IContextValues = {
    id,
    password,
    company,
    savedCompanyInfo,
    isValid,
    message,
    captchaImageUrl,
    captchaAnswer,
    selectedLanguage,
    savedAccountInfo,
    isCompanySaved,
    useAutoLogin,
  };

  const contextActions: IContextActions = {
    setId,
    setPassword,
    setCompany,
    setSavedCompanyInfo,
    setValidation,
    setMessage,
    setCaptchaImageUrl,
    setCaptchaAnswer,
    setSelectedLanguage,
    setSavedAccountInfo,
    setIsCompanySaved,
    setUseAutoLogin,
  };

  return (
    <ValuesContext value={contextValues}>
      <ActionsContext value={contextActions}>{children}</ActionsContext>
    </ValuesContext>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};
