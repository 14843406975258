import {
  Root,
  Submit,
} from '@daouoffice/ui/lib/foundation/Form/primitives/Root';
import { Field } from '@daouoffice/ui/lib/foundation/Form/primitives/Field';
import { Header } from './parts/Header';
import { Body } from './parts/Body';
import { Footer } from './parts/Footer';
import { Logo } from './parts/Logo';
import { Title } from './parts/Title';
import { Options } from './parts/Options';
import { Description } from './parts/Description';
import { Copyright } from './parts/Copyright';
import { LoginButton } from './parts/LoginButton';
import { CompanySelector } from './parts/CompanySelector';
import { LanguageSelector } from './parts/LanguageSelector';
import { Message } from './parts/Message';
import { Captcha } from './parts/Captcha';

export const LoginForm = {
  Root,
  Header,
  Body,
  Footer,
  Logo,
  Title,
  Field,
  Message,
  Captcha,
  Submit,
  LoginButton,
  Options,
  CompanySelector,
  LanguageSelector,
  Description,
  Copyright,
};

export default './types';
