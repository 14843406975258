import clsx from 'clsx';

interface Props {
  className?: string;
  description: string;
}
export function Description({ className, description }: Props) {
  return (
    <p className={clsx('description whitespace-pre-line', className)}>
      {description}
    </p>
  );
}
