import { useEffect, useState } from 'react';
import { FaviconTitleProvider } from '@dop-ui/react/app/providers/ui/favicon-title-provider';
import { SelectPrimitives } from '@dop-ui/react/shared/ui/select';
import { CompanySaveToggle } from './CompanySaveToggle';
import { CompanyInfo } from '../../../../lib/login';
import { useActionsContext, useValuesContext } from '../../Context';
import { getCompanyList } from '../../../../lib/login/company';

export interface Props {
  title: string;
  companyAutoSaverText: string;
  companyNotFound: string;
  referer?: string;
}

export function CompanySelector({
  title,
  companyAutoSaverText,
  companyNotFound,
  referer,
}: Props) {
  const { company, savedCompanyInfo } = useValuesContext();
  const { setCompany, setMessage, setIsCompanySaved } = useActionsContext();
  const [companyList, setCompanyList] = useState<CompanyInfo[]>([]);

  const getCompanyByValue = (value: string) => {
    return companyList.find((company) => company.name === value);
  };

  const onChangeHandler = (value: string) => {
    setCompany(getCompanyByValue(value) ?? ({} as CompanyInfo));
  };

  useEffect(() => {
    const fetchCompany = () => {
      const formatReferer = referer?.includes('"')
        ? referer.replace(/^"(.*)"$/, '$1')
        : referer;

      void getCompanyList(formatReferer)
        .then((result) => {
          if (!result.data) {
            setMessage(companyNotFound);
          } else {
            setCompany(result?.data.companyList[0] ?? ({} as CompanyInfo));
          }
          setCompanyList(result.data?.companyList ?? []);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    void fetchCompany();
  }, [companyNotFound, referer, setCompany, setMessage]);

  useEffect(() => {
    if (
      savedCompanyInfo &&
      companyList.find((com) => com.companyId === savedCompanyInfo.companyId)
    ) {
      setCompany(savedCompanyInfo);
      setIsCompanySaved(true);
    }
  }, [companyList, savedCompanyInfo, setCompany, setIsCompanySaved]);

  return companyList.length > 1 ? (
    <>
      <FaviconTitleProvider
        faviconImageURL={company.faviconImagePath}
        browserTitle={company.browserTitle}
      />
      <label className="font-medium text-sm text-[--dop-color-text-basic-level1]">
        {title}
      </label>
      <SelectPrimitives.Root
        defaultLabel={company.name ?? ''}
        value={company.name}
        onChange={(value) => onChangeHandler(value)}
      >
        <SelectPrimitives.Trigger className="mt-[8px] mb-[16px] w-full" />
        <SelectPrimitives.Content className="z-[10] !w-[400px] max-h-[240px] overflow-auto">
          {companyList.map((company) => (
            <SelectPrimitives.Option
              key={company.companyId}
              value={company.name}
            >
              {company.name}
            </SelectPrimitives.Option>
          ))}
        </SelectPrimitives.Content>
      </SelectPrimitives.Root>
      <CompanySaveToggle title={companyAutoSaverText} />
    </>
  ) : (
    <></>
  );
}

export default CompanySelector;
