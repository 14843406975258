'use client';

import { useSearchParams } from 'next/navigation';
import { Provider } from '@daouoffice/next/components/LoginForm/Context';
import { WebLoginClient } from '@daouoffice/next/components/LoginForm/template/Web/WebLoginClient';

export function LoginForm() {
  const searchParam = useSearchParams();
  const nextURL = searchParam.get('nextUrl');

  return (
    <Provider>
      <WebLoginClient baseUrl={nextURL ?? '/'} />
    </Provider>
  );
}
