import { DEFAULT_SIZE, DEFAULT_TEXT_SIZE } from './constants';
import type { ShapeTypes } from './types';

export function getFallbackString(str: string, length: number = 1) {
  const cutStrLength = length > 0
    ? Math.min(str.length, length)
    : str.length;

  return (str.substring(0, cutStrLength) || '').toUpperCase();
}

/**
 * 대체 텍스트의 크기 계산
 * - squirecle 타입과 다른 타입의 폰트 크기를 동일하게 보이기 위해 별도로 계산함.
 * - 아바타의 기본 크기인 88을 기준으로 48px이 반환되도록 구성
 * - squirecle 타입은 svg내에 위치하여 svg의 크기에 따라 자동으로 스케일링 되므로 기본 사이즈 반환
 * @param size 
 * @returns 
 */
export function getFallbackStringSize(
  size: number, 
  type: ShapeTypes = 'square', 
  length: number = 1
) {
  return type === 'squirecle' 
    ? `${Math.floor(DEFAULT_TEXT_SIZE / length) + (length > 1 ? 10 : 0)}` 
    : `${Math.floor(size * (DEFAULT_TEXT_SIZE / DEFAULT_SIZE))}px`
}
