import { useState } from 'react';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { NoticeDialog } from '../notice-dialog';
import { LoginErrorDialogProps } from '../types';

interface DeniedAccessInfo {
  accessTime: string;
  accessIp: string;
  accessLocale: string;
  accessDevice: string;
}

export function AccessDeniedDialog({
  data,
  onCloseDialog,
}: LoginErrorDialogProps) {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(true);

  if (!data) {
    return null;
  }

  const { accessTime, accessIp, accessLocale, accessDevice } =
    data as DeniedAccessInfo;

  if (!accessTime || !accessIp || !accessLocale || !accessDevice) {
    return null;
  }

  return (
    <NoticeDialog
      title={t('dialog.accessDeny.title')}
      open={isOpen}
      onOpenChange={setIsOpen}
      onCloseDialog={onCloseDialog}
    >
      <div className="mt-[16px]">
        <p className="text-[#363636]">{t('dialog.accessDeny.description')}</p>
        <p className="text-[#363636]">{t('dialog.accessDeny.description2')}</p>
      </div>
      <div className="mt-[16px] p-[16px] w-full rounded-[16px] bg-[#f7f9f9]">
        <div className="flex py-[4px]">
          <p className="text-[#808080] w-[100px] flex-shrink-0">
            {t('dialog.accessDeny.datetime')}
          </p>
          <p className="text-[#363636]">{accessTime}</p>
        </div>
        <div className="flex py-[4px]">
          <p className="text-[#808080] w-[100px] flex-shrink-0">
            {t('dialog.accessDeny.ip')}
          </p>
          <p className="text-[#363636]">{accessIp}</p>
        </div>
        <div className="flex py-[4px]">
          <p className="text-[#808080] w-[100px] flex-shrink-0">
            {t('dialog.accessDeny.locale')}
          </p>
          <p className="text-[#363636]">{accessLocale}</p>
        </div>
        <div className="flex py-[4px]">
          <p className="text-[#808080] w-[100px] flex-shrink-0">
            {t('dialog.accessDeny.device')}
          </p>
          <p className="text-[#363636]">{accessDevice}</p>
        </div>
      </div>
      <Button
        className="mt-[24px]"
        shape="rect"
        size="large"
        autosize="full"
        variant="solid"
        colorset="level1"
        onClick={() => {
          setIsOpen(false);
          onCloseDialog();
        }}
      >
        {t('dialog.confirm')}
      </Button>
    </NoticeDialog>
  );
}
