import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export function Header({ className, children }: PropsWithChildren<Props>) {
  return (
    <>
      {!className ? (
        <div className="wrap_logo grid justify-center">{children}</div>
      ) : (
        <div className={className}>{children}</div>
      )}
    </>
  );
}
