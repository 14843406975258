import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useEffect } from 'react';
import { useContext } from '../../../Context';
import { CheckBox } from '@dop-ui/react/shared/ui/check-box';
import { PasswordResetDialog } from '../../../components/password-reset-dialog';

export function ChatOptions() {
  const { useAutoLogin, setUseAutoLogin } = useContext();
  const { t } = useTranslation('component');
  const localizedText = {
    autoLogin: t('login.form.autoLogin'),
    findPassword: t('login.form.findPassword'),
  };

  const checkAutoLogin = () => {
    const useAutoLogin = window.localStorage.getItem('useAutoLogin');
    if (useAutoLogin === 'true') {
      setUseAutoLogin(true);
    }
  };

  useEffect(() => {
    checkAutoLogin();
  }, []);

  return (
    <div className="group left">
      <CheckBox
        id="check01"
        defaultChecked={useAutoLogin}
        onChange={(checked?: boolean) => {
          setUseAutoLogin(checked ?? false);
        }}
        label={localizedText.autoLogin}
      />
      <p className="divider dot"></p>
      <PasswordResetDialog />
    </div>
  );
}
