import clsx from 'clsx';
import { PropsWithChildren } from 'react';

export interface Props {
  className?: string;
  title: string;
  onClick: (e: React.MouseEvent) => void;
}

export function TextFieldButton({
  className,
  title,
  children,
  onClick,
}: PropsWithChildren<Props>) {
  return (
    <button
      className={clsx('btn_icon', className)}
      onClick={onClick}
      tabIndex={-1}
    >
      <i className="btn_icon ic_medium">{children}</i>
      <span className="txt">{title}</span>
    </button>
  );
}
